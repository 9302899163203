export const FilterData = {
    types: [
        { display: 'Videojuego', value: 'videogame' },
        { display: 'Consola', value: 'console' },
        { display: 'Accesorio', value: 'accessory' },
        { display: 'Coleccionable', value: 'collectable' }
    ],
    consoles: [
        { display: 'PS1', value: 'ps1' },
        { display: 'PS2', value: 'ps2' },
        { display: 'PS3', value: 'ps3' },
        { display: 'PS4', value: 'ps4' },
        { display: 'PS5', value: 'ps5' },
        { display: 'PS5 Slim', value: 'ps5-slim' },
        { display: 'Xbox', value: 'xbox' },
        { display: 'Xbox 360', value: 'xbox-360' },
        { display: 'Xbox One', value: 'xbox-one' },
        { display: 'Xbox Series S', value: 'xbox-series-s' },
        { display: 'Xbox Series X', value: 'xbox-series-x' },
        { display: 'PSVita', value: 'psvita' },
        { display: 'PSP', value: 'psp' },
        { display: 'Wii', value: 'wii' },
        { display: 'WII U', value: 'wii-u' },
        { display: 'N64', value: 'n64' },
        { display: 'SNES', value: 'snes' },
        { display: 'NES', value: 'nes' },
        { display: 'Atari 2600', value: 'atari-2600' },
        { display: 'Sega Genesis', value: 'sega-genesis' },
        { display: 'Sega Dreamcast', value: 'sega-dreamcast' },
        { display: 'Sega Saturn', value: 'sega-saturn' },
        { display: 'Sega Nomad', value: 'sega-nomad' },
        { display: 'Sega GameGear', value: 'sega-gamegear' },
        { display: 'Gameboy', value: 'gameboy' },
        { display: 'Gameboy Color', value: 'gameboy-color' },
        { display: 'Gameboy Pocket', value: 'gameboy-pocket' },
        { display: 'Gameboy Advanced', value: 'gameboy-advanced' },
        { display: 'Gameboy Advanced SP', value: 'gameboy-advanced-sp' },
        { display: 'Gamecube', value: 'gamecube' },
        { display: 'DS', value: 'ds' },
        { display: 'DS Lite', value: 'ds-lite' },
        { display: 'DSi', value: 'dsi' },
        { display: '3DS', value: '3ds' },
        { display: 'New 3DS', value: 'new-3ds' },
        { display: 'New 3DS XL', value: 'new-3ds-xl' },
        { display: '2DS', value: '2ds' },
        { display: 'New 2DS XL', value: 'new-2ds-xl' },
        { display: 'Nintendo Switch', value: 'nintendo-switch' },
        { display: 'Nintendo Switch OLED', value: 'nintendo-switch-oled' },
        { display: 'Nintendo Switch Lite', value: 'nintendo-switch-lite' }
    ],
    tags: [
        { display: 'Original', value: 'Original' },
        { display: 'Genérico', value: 'GENERICO' },
        { display: 'Clon', value: 'Clon' },
        { display: 'CIB (completo en caja)', value: 'CIB' },
        { display: 'En caja', value: 'En Caja' },
        { display: 'Sin caja', value: 'SIN CAJA' },
        { display: 'Greatest Hits', value: 'Greatest Hits' },
        { display: 'Black Label', value: 'black label' },
        { display: 'Japones', value: 'Japones' },
    ]
}

export const FilterCategories = [
    {display: 'Tipo', value: 'types'},
    {display: 'Consola', value: 'consoles'},
    {display: 'Etiqueta', value: 'tags'}
]
