function getConsoleLogo(consoleTitle) {
  const consoleLogos = {
    ps1: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Playstation_logo_colour.svg/201px-Playstation_logo_colour.svg.png",
    ps2: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/PlayStation_2_logo.svg/768px-PlayStation_2_logo.svg.png",
    ps3: "https://upload.wikimedia.org/wikipedia/commons/0/05/PlayStation_3_logo_%282009%29.svg",
    ps4: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PlayStation_4_logo_and_wordmark.svg/755px-PlayStation_4_logo_and_wordmark.svg.png",
    ps5: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/PlayStation_5_logo_and_wordmark.svg/768px-PlayStation_5_logo_and_wordmark.svg.png",
    'ps5-slim': "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/PlayStation_5_logo_and_wordmark.svg/768px-PlayStation_5_logo_and_wordmark.svg.png",
    xbox: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Vectorial_Xbox_logo.svg/1134px-Vectorial_Xbox_logo.svg.png",
    'xbox360': "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Xbox_360_logo.svg/258px-Xbox_360_logo.svg.png",
    'xbox-one': "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/X_Box_One_logo.svg/246px-X_Box_One_logo.svg.png",
    'xbox-series-s': "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Xbox_Series_X_S_black.svg/768px-Xbox_Series_X_S_black.svg.png",
    'xbox-series-x': "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Xbox_Series_X_S_black.svg/768px-Xbox_Series_X_S_black.svg.png",
    psvita: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/PlayStation_Vita_logo.svg/1200px-PlayStation_Vita_logo.svg.png",
    psp: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/PSP_Logo.svg/1130px-PSP_Logo.svg.png",
    wii: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Wii.svg/423px-Wii.svg.png",
    wiiu: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/WiiU.svg/1047px-WiiU.svg.png",
    n64: "https://upload.wikimedia.org/wikipedia/it/thumb/0/04/Nintendo_64_Logo.svg/971px-Nintendo_64_Logo.svg.png?20070701103538",
    snes: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/SNES_logo.svg/444px-SNES_logo.svg.png",
    nes: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/NES_logo.svg/216px-NES_logo.svg.png?20230801110942",
    switch: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Nintendo_Switch_Logo.svg/900px-Nintendo_Switch_Logo.svg.png",
    'switch-oled': "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Nintendo_Switch_Logo.svg/900px-Nintendo_Switch_Logo.svg.png",
    'switch-lite': "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Nintendo_Switch_Logo.svg/900px-Nintendo_Switch_Logo.svg.png",
    atari2600: "https://upload.wikimedia.org/wikipedia/commons/0/08/Atari_2600_logo.svg",
    'sega-genesis': "https://upload.wikimedia.org/wikipedia/commons/7/74/Sega_genesis_logo.svg",
    'sega-dreamcast': "https://upload.wikimedia.org/wikipedia/commons/7/7e/Dreamcast_logo.svg",
    'sega-saturn': "https://upload.wikimedia.org/wikipedia/commons/7/78/Sega_Saturn_Black_Logo.svg",
    'sega-nomad': "https://upload.wikimedia.org/wikipedia/en/2/2b/Sega_Nomad.svg",
    'sega-gamegear': "https://upload.wikimedia.org/wikipedia/commons/4/41/Game_gear_us-jp_logo.svg",
    gameboy: "https://upload.wikimedia.org/wikipedia/commons/3/39/Gameboy_logo.svg",
    'gameboy-color': "https://upload.wikimedia.org/wikipedia/commons/c/c5/Game_Boy_Color_logo.svg",
    'gameboy-pocket': "https://upload.wikimedia.org/wikipedia/commons/5/5a/Game_Boy_Pocket_Logo.svg",
    'gameboy-advanced': "https://upload.wikimedia.org/wikipedia/commons/5/50/Game_Boy_Advance_logo.svg",
    'gameboy-advanced-sp': "https://upload.wikimedia.org/wikipedia/commons/7/72/Game_Boy_Advance_SP_logo.svg",
    gamecube: "https://upload.wikimedia.org/wikipedia/commons/0/00/Nintendo_GameCube-06.svg",
    ds: "https://upload.wikimedia.org/wikipedia/commons/a/af/Nintendo_DS_Logo.svg",
    'ds-lite': "https://upload.wikimedia.org/wikipedia/commons/4/4f/Nintendo_DS_Lite_logo.svg",
    dsi: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Nintendo_DSi_logo.svg",
    '3ds': "https://upload.wikimedia.org/wikipedia/commons/1/19/Nintendo_3ds_logo.svg",
    'new-3ds': "https://upload.wikimedia.org/wikipedia/commons/b/be/New_Nintendo_3DS_logo.svg",
    'new-3ds-xl': "https://upload.wikimedia.org/wikipedia/commons/9/99/New_Nintendo_3DS_XL.svg",
    '2ds': "https://upload.wikimedia.org/wikipedia/commons/8/85/Nintendo_2DS_%28logo%29.svg",
    'new-2ds-xl': "https://upload.wikimedia.org/wikipedia/commons/4/48/New_Nintendo_2DS_XL.svg",
  };

  const logoUrl = consoleLogos[consoleTitle.toLowerCase()];

  if (logoUrl) {
    return <img className="object-contain inline w-full h-16 md:h-8" src={logoUrl} />;
  } else {
    // Default case for unknown consoles
    return null;
  }
}



export const ConsoleLogo = ({ console, consoleCode }) => {
  const consoleLogo = getConsoleLogo(consoleCode);

  return (
    <div className="console-logo first:inline">
      {consoleLogo}
    </div>
  );
};
