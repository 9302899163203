import {useEffect, useRef} from "react";

export const News = ({newsContent}) => {


    return <div className={"bg-zinc-100 flex md:h-[70vh] py-4 mb-8"}>
        <div className="h-full m-4">
            <div  className="text-center m-5 font-bold text-4xl animate__animated animate__tada animate__slow animate__infinite">
                ¡Novedades Ready!
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 h-[50%]" >
                {newsContent && newsContent.map((news) => (
                    <div key={news.id} className="h-[65%] w-full">
                        <div className="flex flex-col h-72 items-center justify-center hover:cursor-pointer">
                            {news.video && <a className="h-full md:w-full" rel="noreferrer"
                                              style={{height: "100%"}} target="_blank" href="https://www.instagram.com/reel/C2vfjMFvpty/">
                                <video muted={true} autoPlay={true} loop={true}
                                       className="rounded-2xl object-cover block h-full w-full"
                                       src={news.src}>
                                    <source src={news.src} type="video/mp4" />
                                </video>

                            </a>}
                            {news.image && <a className="h-full" rel="noreferrer"
                                              target="_blank" href="https://www.instagram.com/reel/C2vfjMFvpty/">
                                <img src={news.src}/>
                            </a>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
}