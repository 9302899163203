export const SinpeModal = (props) => {
    const steps = [
        { title: "Enviar monto", title2: "a 7237-5342 (A nombre de Joseph Zamora Murillo).", img: "/images/SINPE-Steps/step3.png" },
        { title: "Toma foto del comprobante", img: "/images/SINPE-Steps/step5.png" },
        { title: "Enviar comprobante a 7237-5342 en WhatsApp", img: "/images/SINPE-Steps/step7.png" },
    ];

    const stopPropagation = (e) => e.stopPropagation();

    return (
        <div onClick={() => { props.setShowSinpeModal(false); }} className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex">
            <div onClick={stopPropagation} className="relative bg-white m-auto flex-col flex rounded-lg text-center px-4 py-8 overflow-hidden w-[450px]">

                <div onClick={() => { props.setShowSinpeModal(false); }} className="cursor-pointer absolute top-1 right-0 p-4">
                    <img src="/svgs/x-icon.svg" />
                </div>

                <h2 className="text-xl font-bold mb-4 cursor-default">Pasos para Pagar con SINPE Móvil</h2>
                {
                    steps.map((step, i) => {
                        return (
                            <div key={'step' + i} className={`flex items-center my-2 ${(i === 0 || i % 2 === 0) ? "" : "flex-row-reverse"}`}>
                                <img className={`rounded-[8px] w-[50px] ${(i === 0 || i % 2 === 0) ? "mr-3" : "ml-3"}`} src={step.img} alt="Step Image" />
                                {i === 0 ? <>
                                    <label className="inline-block text-gray-500 font-[600] text-left">{step.title} &nbsp;₡{props.prize}&nbsp; {step.title2}</label>
                                </> : <label className="inline-block text-gray-500 font-[600] text-left">{step.title}</label>}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}