import React from 'react';
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Catalogue from "../Catalogue/Catalogue";

const ProductsView = () => {
    return (
        <div className="w-full">
            <Navbar />
            <div className="flex h-full">
                <div className="flex flex-col h-full">
                    <Catalogue defaultTag={""} productsView={true}/>
                </div>

            </div>

        </div>

    );
};
export default ProductsView;
