import React from 'react';

const ConsoleLogo = ({consoleFile, consoles}) => {
    const consoleImagesRoute = "/images/console_logos/";

    const onClick = () => {
        document.location.href = `/products?consoles=${consoles}`;
    }

    return <div onClick={onClick}
    className="cursor-pointer bg-gray-200 rounded-lg p-4 flex items-center justify-center">
    <img src={consoleImagesRoute + consoleFile} alt="Item 1" className="w-24 h-24 object-contain" />
  </div>
}


const ConsolesCatalogue = () => {
    

    return (
    <div className="flex justify-center items-center bg-gray-100 my-12">
        <div className="bg-white shadow-lg rounded-lg p-6 space-y-4">
            <h2 className="text-xl font-bold text-gray-800 text-center">🎮Ofrecemos de todo🎮</h2>

            <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
            
            <ConsoleLogo consoleFile={"atari2600.svg"} consoles="atari2600"/>
            <ConsoleLogo consoleFile={"n64.png"} consoles="n64"/>
            <ConsoleLogo consoleFile={"nes.png"} consoles="nes,famicom,nes-top-loader,nes-mini"/>
            <ConsoleLogo consoleFile={"snes.png"} consoles="snes,super-famicom,snes-junior,snes-mini"/>
            <ConsoleLogo consoleFile={"ps1.png"} consoles="ps1,psone,ps-classic"/>
            <ConsoleLogo consoleFile={"ps2.png"} consoles="ps2,ps2-fat,ps2-slim,ps2-japonés"/>
            <ConsoleLogo consoleFile={"ps3.svg"} consoles="ps3,ps3-fat,ps3-slim,ps3-super-slim"/>
            <ConsoleLogo consoleFile={"ps4.png"} consoles="ps4,ps4-slim,ps4-fat,ps4-pro"/>
            <ConsoleLogo consoleFile={"ps5.png"} consoles="ps5,ps5-slim"/>
            <ConsoleLogo consoleFile={"psp.png"} consoles="psp"/>
            <ConsoleLogo consoleFile={"psvita.png"} consoles="psvita"/>
            <ConsoleLogo consoleFile={"switch.png"} consoles="switch,switch-oled,switch-lite"/>
            <ConsoleLogo consoleFile={"gc.svg"} consoles="gamecube"/>
            <ConsoleLogo consoleFile={"wii.png"} consoles="wii,wii-mini"/>
            <ConsoleLogo consoleFile={"wiiu.png"} consoles="wiiu"/>
            <ConsoleLogo consoleFile={"xbox.png"} consoles="xbox"/>
            <ConsoleLogo consoleFile={"xbox360.png"} consoles="xbox360,xbox360-slim,xbox360-fat,xbox360-slim-e"/>
            <ConsoleLogo consoleFile={"xboxone.png"} consoles="xbox-one,xbox-one-fat,xbox-one-s,xbox-one-x"/>
            <ConsoleLogo consoleFile={"xboxseriesx.png"} consoles="xbox-series-x,xbox-series-s"/>
            <ConsoleLogo consoleFile={"2ds.svg"} consoles="2ds,new-2ds-xl"/>
            <ConsoleLogo consoleFile={"3ds.svg"} consoles="3ds,3ds-xl"/>
            <ConsoleLogo consoleFile={"new3ds.svg"} consoles="new-3ds"/>
            <ConsoleLogo consoleFile={"new3dsxl.svg"} consoles="new-3ds-xl"/>
            <ConsoleLogo consoleFile={"ds.svg"} consoles="ds"/>
            <ConsoleLogo consoleFile={"dsi.svg"} consoles="dsi,dsi-xl"/>
            <ConsoleLogo consoleFile={"dslite.svg"} consoles="ds-lite"/>
            <ConsoleLogo consoleFile={"gameboy.svg"} consoles="gameboy,gameboy-dmg"/>
            <ConsoleLogo consoleFile={"gameboyadvance.svg"} consoles="gameboy-advanced,gameboy-micro"/>
            <ConsoleLogo consoleFile={"gameboyadvancesp.svg"} consoles="gameboy-advance-sp,gameboy-micro"/>
            <ConsoleLogo consoleFile={"gameboycolor.svg"} consoles="gameboy-color"/>
            <ConsoleLogo consoleFile={"gameboypocket.svg"} consoles="gameboy-pocket"/>
            <ConsoleLogo consoleFile={"gamegear.svg"} consoles="sega-gamegear"/>
            <ConsoleLogo consoleFile={"genesis.svg"} consoles="sega-genesis,sega-genesis-2,sega-genesis-3,sega-genesis-mini"/>
            <ConsoleLogo consoleFile={"nomad.svg"} consoles="sega-nomad"/>
            <ConsoleLogo consoleFile={"saturn.svg"} consoles="sega-saturn"/>
            <ConsoleLogo consoleFile={"dreamcast.svg"} consoles="sega-dreamcast"/>

            </div>
        </div>
    </div>

  );
};

export default ConsolesCatalogue;