import 'animate.css';
import './App.css';
import Categories from './components/Categories/Categories';
import Navbar from './components/Navbar/Navbar';
import {News} from "./components/News/News";
import ProductCarousel from "./components/Catalogue/Carousel";
import {useEffect, useState} from "react";
import {ProductsAPI} from "./api/products";
import ConsolesCatalogue from './components/ConsolesCatalogue/ConsolesCatalogue';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-D4ZT2LB4GG');
export const trackGoogleAnalyticsEvent = (
    category,
    action,
    label
  ) => {
    console.info("GA event:", category, ":", action, ":", label);
    // Send UA Event
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

function App() {
    const [products, setProducts] = useState();
    const api = new ProductsAPI();
    const bannerUrl = "/images/bg2.png";

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    useEffect(() => {
        if (!products) {
            api.getProducts({
                tags: "DESTACADO"
            }).then(
                (data) => {
                    setProducts(data.results);
                }
            );
        }

    }, [])

    return (
        <div className="w-full">
            <Navbar />
            <img className={"w-full"} src={bannerUrl} alt="imagen de inicio" />
            <div className="flex flex-col h-full">
                <ProductCarousel products={products}></ProductCarousel>
                <ConsolesCatalogue></ConsolesCatalogue>
                <News newsContent={[
                    {id: 1, video: true, src: "/videos/video.mp4"},
                    {id: 2, video: true, src: "/videos/video2.mp4"},
                    {id: 3, video: true, src: "/videos/video3.mp4"},
                    {id: 4, video: true, src: "/videos/video4.mp4"},
                ]}></News>
                <Categories />
            </div>


        </div>
        
    );
}

export default App;
