import React, {useEffect, useState} from 'react';
import {ProductsAPI} from "../../api/products";
import Product from '../Product/Product';
import FilterModal from '../FilterModal/FilterModal';
import { IoFilterSharp } from "react-icons/io5";
import {useSearchParams} from "react-router-dom";
import Loader from "../Loader/Loader";
import { trackGoogleAnalyticsEvent } from '../../App';

const Catalogue = ({ productsView, defaultTag }) => {
    const [products, setProducts] = useState();
    const [page, setPage] = useState(1); // Track the current page
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loading, setLoading] = useState(true);
    const [noMorePagesLeft, setNoMorePagesLeft] = useState(false);

    const [_isopen, setIsopen] = useState(false);
    const handleOpen = () => {
        setIsopen(true);
        trackGoogleAnalyticsEvent("user_action", `link:filter`)
    }
    const handleClose = () => setIsopen(false);
    const [searchParams, _] = useSearchParams();
    const api = new ProductsAPI();

    useEffect(() => {
        if(searchParams) {
            const query = 
            `${searchParams.get("q") || ""}|${searchParams.get("consoles")}|${searchParams.get("types")}|${searchParams.get("tags")}`;
            trackGoogleAnalyticsEvent("user_action", `search:${query}`)
        }
    }, [searchParams]);

    useEffect(() => {
        if (!products) {
            api.getProducts({
                q: searchParams.get("q") || "", consoles: searchParams.get("consoles") || "", types: searchParams.get("types") || "",
                tags: searchParams.get("tags") || defaultTag
            }).then(
                (data) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)
                    setProducts(data.results);
                }
            );
        }

    }, [])

    useEffect(() => {
        if(!productsView || !hasNextPage) return;

        // Function to fetch more data from the API
        const fetchData = async () => {
            if(loading || noMorePagesLeft) return;
            setLoading(true);
          try {
            const getProductsQuery = {
                q: searchParams.get("q") || "",
                consoles: searchParams.get("consoles") || "",
                types: searchParams.get("types") || "",
                page: page+1,
                tags: searchParams.get("tags") || "",
            };
            const response = await api.getProducts(getProductsQuery);
            setProducts((prevProducts) => [...prevProducts, ...response.results]);
            setPage((prevPage) => prevPage + 1);
            setHasNextPage(response.next);
          } catch (error) {
            console.error('Error fetching data:', error);
            if(error.response.status === 404) {
                setNoMorePagesLeft(true);
            }
          } finally {
              setTimeout(() => {
                  setLoading(false);
              }, 1000)
          }
        };

        // Event listener for scrolling
        const handleScroll = async () => {
          const offset = 500; // Load next batch of products before reaching the bottom
          if (
            Math.round(window.innerHeight + document.documentElement.scrollTop) >=
            document.documentElement.offsetHeight - offset
          ) {
            await fetchData();
          }
        };

        // Add event listener when the component mounts
        document.removeEventListener('scroll', handleScroll);
        document.addEventListener('scroll', handleScroll);

        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('scroll', handleScroll);
        };
  }, [page, loading]); // Re-run the effect when the page changes

    const handleApplyFilters = (selectedSubcategories) => {
        const [consoles, types, tags] = [
            searchParams.get('consoles'), searchParams.get('types'), searchParams.get('tags')
        ]

        const [currentConsolesPlusNew, currentTypesPlusNew, currentTagsPlusNew] = [
            new Set([...selectedSubcategories.consoles].concat(consoles ? consoles.split(","): [])),
            new Set([...selectedSubcategories.types].concat(types ? types.split(","): [])),
            new Set([...selectedSubcategories.tags].concat(tags ? tags.split(","): []))
        ]
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('q', searchParams.get('q') || "")
        newSearchParams.set('consoles', Array.from(currentConsolesPlusNew).join(","))
        newSearchParams.set('types', Array.from(currentTypesPlusNew).join(","))
        newSearchParams.set('tags', Array.from(currentTagsPlusNew).join(","))

        const pathname = window.location.pathname.includes("/products") ? window.location.pathname: "/products"
        window.history.replaceState({}, '', `${pathname}?${newSearchParams.toString()}`);
        window.location.reload();
    };

    const buttonClasses = products ? "w-[25%] md:w-[15%]": "";

    return (
        <>
            
            <div className="flex h-full">
                <div className="flex flex-col flex-grow p-4">
                    <div className={"justify-center items-center flex relative cursor-pointer bg-zinc-200 rounded-lg mx-4 px-2 " + buttonClasses}>
                        {
                            productsView && <button className='flex items-center content-start  text-black' onClick={handleOpen}>
                            Filtros
                            <IoFilterSharp className="w-6 mr-4"/>
                        </button>
                        }

                        {_isopen && (
                            <FilterModal close={handleClose} handleApplyFilters={handleApplyFilters}/>
                        )}

                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 h-[50%]">
                        {products && products.map((product) => (
                            <div key={product.id} className="p-4 h-full md:h-[65%] w-full">
                                <Product product={product}/>
                            </div>
                        ))}
                        {(!products || loading) && <Loader></Loader>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Catalogue;
