import React, { useState } from 'react'
import { IoCheckbox, IoClose } from "react-icons/io5";
import CategoryMenu from '../FilterCategory/FilterCategory.js';

export default function FilterModal({close, handleApplyFilters}) {

    return (
    <>
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div className='bg-white p-2 rounded md:w-[25%] w-3/4 md:h-3/5'>
                <div className='flex flex-row justify-end'>
                    <button onClick={close}><IoClose size={20} /></button>
                </div>

                <CategoryMenu handleApplyFilters={handleApplyFilters}/>
                
            </div>
        </div>
        
        
    </>
  )
}
