import React from 'react';

const CategoriesCard = (props) => {

    return (
        <>
            <div onClick={() => props.onClick({
                console: props.console,
                type: props.type
            })} className="flex items-center justify-center border-2 border-zinc-200 rounded-lg bg-white p-4">
                <img src={props.logoUrl} className="h-[250px] object-contain w-full" alt={props.alt}  title={props.title}
                />
            </div>
        </>
    );
};
export default CategoriesCard;