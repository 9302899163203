import {Carousel} from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ProductCard from "../Product/Product";


const ProductCarousel = ({products}) => {
    return (
            <Carousel swipeable={false} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
                {products && products.map(product => <ProductCard key={product.id} product={product}></ProductCard>)}
            </Carousel>
        );
}

export default ProductCarousel;