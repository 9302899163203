import React, {useEffect, useState} from 'react';
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import { GrMapLocation, GrSearch } from "react-icons/gr";
import {useSearchParams} from "react-router-dom";
import { trackGoogleAnalyticsEvent } from '../../App';

const Navbar = () => {
    const [searchString, setSearchString] = useState("");
    const [searchParams, _] = useSearchParams();
    const search = (e) => {
        e.preventDefault();

        if(e.key !== "Enter") return;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('q', searchString);
        const pathname = window.location.pathname.includes("/products") ? window.location.pathname: "/products"
        window.history.replaceState({}, '', `${pathname}?${searchParams.toString()}`);
        window.location.reload();
    }

    const whatsappLink = () => {
        trackGoogleAnalyticsEvent("user_action", `link:whatsapp`)
        let baseLink = "https://wa.me/50672375342?text=Hola, me gustaria saber mas sobre el negocio.";
        window.open(baseLink, '_blank').focus();
    }

    const googleMapsLink = () => {
        trackGoogleAnalyticsEvent("user_action", `link:google_maps`)
        let baseLink = "https://maps.app.goo.gl/V8W5XcAZNbqujZsm6";
        window.open(baseLink, '_blank').focus();
    }

    useEffect(() => {
        setSearchString(searchParams.get("q") || "");
    }, [searchParams])


    return (
        <>
            <div>
                <div className="bg-[#E6007E]">
                    <div className="flex-col flex">
                        <div className="w-full">
                            <div className="navbar h-16 justify-between items-center mx-auto md:px-4 flex">
                                <div id="logo" className="animate__animated animate__fadeInLeft animate__slow">
                                    <img src="/images/readyGamesLogo.png"
                                        className="block btn- h-16 w-auto hover:cursor-pointer" alt="homepage"
                                         onClick={() => document.location.href = "/"}
                                    />
                                </div>
                                <div className="md:grow block mr-auto md:ml-16 relative max-w-xs">
                                    <p onClick={search} className="pl-3 items-center flex absolute inset-y-0 left-0">
                                        <span className="justify-center items-center flex">
                                            <GrSearch className="h-6 w-6 color-gray"/>
                                        </span>
                                    </p>
                                    <input value={searchString} onKeyUp={search} onChange={e => setSearchString(e.target.value)} placeholder="Escriba aqui para buscar..." type="search" className="border border-gray-300 focus:ring-indigo-600
                                        focus:border-indigo-600 sm:text-sm w-full rounded-lg pt-2 pb-2 pl-10 px-3 py-2"
                                    />
                                </div>
                                <div className="md:space-x-6 justify-end items-center ml-auto flex space-x-3">
                                    <div onClick={googleMapsLink} className="justify-center items-center flex relative cursor-pointer">
                                        <GrMapLocation className="h-6 w-6 md:mr-4 ml-4"/>
                                        <p className="hidden md:block font-semibold text-sm">Tienda</p>
                                    </div>
                                    <div onClick={whatsappLink} className="justify-center items-center flex relative cursor-pointer">
                                        <AiOutlineWhatsApp className="h-6 w-6 md:mr-4 md-4"/>
                                        <p className="hidden md:block font-semibold text-sm">WhatsApp</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
