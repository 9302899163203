import React from 'react';
import CategoriesCard from '../CategoriesCard/CategoriesCard.js';

const Categories = () => {

    const onClick = ({console="", type="", tags=""}) => {
        document.location.href = `/products?consoles=${console}&types=${type}&tags=${tags}`;
    }


    const categories = [
        { id: 1, name: 'Nintendo', logoUrl: "/images/nintendoLogo.png", console:"n64,ds,snes,nes,switch,switch-oled,switch-lite,new-2ds-xl,2ds,new-3ds-xl,new-3ds,3ds,dsi,ds-lite,gamecube,gameboy-advanced,gameboy-advanced-sp,gameboy-pocket,gameboy-color,gameboy,wiiu,wii"},
        { id: 2, name: 'Xbox', logoUrl: "/images/xboxLogo.png", console:"xbox,xbox-360,xbox-one,xbox-series-s,xbox-series-x" },
        { id: 3, name: 'Play Station', logoUrl: "/images/playStationLogo.png", console:"ps1,ps2,ps3,ps4,ps5,psp,psvita,ps5-slim" },
        { id: 4, name: 'Sega', logoUrl: "/images/segaLogo.png", console:"sega-nomad,sega-gamegear,sega-genesis,sega-saturn,sega-dreamcast" },
        { id: 5, name: 'Consoles', logoUrl: "/images/consolesLogo.png", type:"console" },
        { id: 6, name: 'Videogames', logoUrl: "/images/videogamesLogo.png", type:"videogame" },
        { id: 7, name: 'Accesories', logoUrl: "/images/accesoriesLogo.png", type:"accessory" },
        { id: 8, name: 'Controllers', logoUrl: "/images/controllerLogo.png", type:"controller" },

    ];

    return (
        <>
            <div className="bg-zinc-100 flex md:h-[70vh] py-4 mb-8" >
                <div className="h-full m-4">
                    <div  className="text-center m-5 font-bold text-4xl animate__animated animate__tada animate__slow animate__infinite">
                       Las Mejores Marcas
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 h-[50%]">
                        {categories.map((categorie) => (
                            <div key={categorie.id} className='cursor-pointer'>
                                <CategoriesCard logoUrl = {categorie.logoUrl} alt = {categorie.name}
                                                title = {categorie.name} console={categorie.console} type={categorie.type}
                                                onClick={onClick}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Categories;