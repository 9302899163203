import React, { useState, useEffect} from 'react';
import {FilterCategories, FilterData} from "./FilterData";
import {MdKeyboardArrowRight} from "react-icons/md";
import {TiArrowBackOutline} from "react-icons/ti";
import {useSearchParams} from "react-router-dom";

const SubcategoryCheckbox = ({ subcategory, isSelected, onCheckboxChange }) => {
  return (
    <div className="border-b border-gray-200 pb-2">
      <label>
        <input
          type="checkbox"
          value={subcategory.value}
          checked={isSelected}
          onChange={() => onCheckboxChange(subcategory)}
        />
        {subcategory.display}
      </label>
    </div>
  );
};

const SubcategoryPage = ({ category, subcategories, selectedSubcategories, onBack, onApplyFilters, setSelectedSubcategories }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryValue = category.value;
    const categoryDisplay = category.display;

    const handleCheckboxChange = (subcategory) => {
      setSelectedSubcategories((prevSelectedSubcategories) => {
        const updatedSubcategories = {
          ...prevSelectedSubcategories,
          [categoryValue]: prevSelectedSubcategories[categoryValue]
            ? [...prevSelectedSubcategories[categoryValue]]
            : [],
        };
  
        const isSelected = updatedSubcategories[categoryValue].includes(subcategory.value);
  
        if (isSelected) {
          updatedSubcategories[categoryValue] = updatedSubcategories[categoryValue].filter(
            (item) => item !== subcategory.value
          );

          searchParams.set(categoryValue, updatedSubcategories[categoryValue].length > 0 ? updatedSubcategories[categoryValue].join(","): "")
          setSearchParams(searchParams);
        } else {
          updatedSubcategories[categoryValue] = [
            ...updatedSubcategories[categoryValue],
            subcategory.value,
          ];
        }
        return updatedSubcategories;
      });
    };
    return (
      <div>
        <button className="border-b border-gray-200 pb-2 text-sm"
                onClick={onBack}><TiArrowBackOutline className="inline"/>Volver al Menú</button>
        <h2 className="border-b border-gray-200 pb-2 mb-6 font-bold">{categoryDisplay}</h2>
        <div className='overflow-y-scroll h-[250px]'>
          {subcategories.map((subcategory) => (
            <SubcategoryCheckbox
              key={subcategory.value}
              subcategory={subcategory}
              isSelected={selectedSubcategories[categoryValue]?.includes(subcategory.value)}
              onCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>
        <button className="button-gradient mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                onClick={() => onApplyFilters(selectedSubcategories)}>Aplicar</button>
      </div>
    );
  };

const CategoryMenu = ({ handleApplyFilters }) => {
  const [searchParams, _] = useSearchParams();
  const [consoles, types, tags] = [
      searchParams.get('consoles'), searchParams.get('types'), searchParams.get('tags')
  ]
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSubcategoryPage, setShowSubcategoryPage] = useState(false);
  const [selectedSubcategories, setSelectedSubcategories] = useState({
    types: types ? types.split(","): [],
    consoles: consoles ? consoles.split(","): [],
    tags: tags ? tags.split(","): [],
  });

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShowSubcategoryPage(true);
  };

  const handleBackToMenu = () => {
    setShowSubcategoryPage(false);
    setSelectedSubcategories((prevSelectedSubcategories) => ({
      ...prevSelectedSubcategories,
      [selectedCategory]: prevSelectedSubcategories[selectedCategory] || [],
    }));
    setSelectedCategory(null);    
  };

  return (
    <div className="w-full">
      {showSubcategoryPage ? (
        <SubcategoryPage
          category={selectedCategory}
          subcategories={FilterData[selectedCategory.value]}
          selectedSubcategories={selectedSubcategories}
          onBack={handleBackToMenu}
          onApplyFilters={handleApplyFilters}
          setSelectedSubcategories={setSelectedSubcategories}
        />
      ) : (
        <>
          <h2 className="font-bold border-gray-200 border-b pb-2">Categorías</h2>
          <ul>
            {FilterCategories.map((category) => (
              <li className="p-4 border-gray-200 border-b flex flex-row justify-between"
                  key={category.value} onClick={() => handleCategoryClick(category)}>
                  <div>{category.display}</div><div className="mt-1"><MdKeyboardArrowRight /></div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CategoryMenu;